import { useSearchParams } from "next/navigation";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import PreviewBanner from "./PreviewBanner";

type State = {
  /**
   * undefined means it's pending, waiting for queryparams to be available.
   *
   * Some functionality, etc metrics, should not be loaded before it's clear wether preview is on or off.
   * We don't want to log metrics when the page is displayed in previewmode i sanity studio.
   * */
  isOn?: boolean;
  toggle: () => void;
  setPreviewMode: (isOn: boolean) => void;
};

const initState: State = {
  isOn: false,
  toggle: () => null,
  setPreviewMode: () => null,
};

const Context = createContext<State>(initState);

export const usePreviewContext = () => useContext(Context);
interface Props {
  children: ReactNode;
}

export const PreviewContextProvider = (props: Props) => {
  const searchParams = useSearchParams();
  const [previewMode, setPreviewMode] = useState(initState.isOn);

  useEffect(() => {
    if (searchParams.get("preview")) {
      setPreviewMode(true);
    }
  }, [searchParams]);

  return (
    <Context.Provider
      value={{
        isOn: previewMode,
        toggle: () => setPreviewMode((s) => !s),
        setPreviewMode: (isOn) => setPreviewMode(isOn),
      }}
    >
      <PreviewBanner />
      {props.children}
    </Context.Provider>
  );
};
