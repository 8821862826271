import { Box, colors, Container, HashLinkTarget, Heading, Text } from "@biblioteksentralen/react";
import { nanoid } from "nanoid";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { usePreviewContext } from "./previewContext";
import { SOMEPreview } from "./SOMEPreview";

export const somePreviewID = nanoid();

export const StudioSOMEPreview = () => {
  const previewContext = usePreviewContext();
  const { t } = useTranslation();

  if (!previewContext.isOn) return null;

  return (
    <Box borderTop={`solid ${colors.lightPeach} .2rem`} backgroundColor={colors.alertYellow}>
      <Container maxW="container.lg" padding="4rem 1rem">
        <Heading as="h2" fontSize="lg">
          <HashLinkTarget id={somePreviewID} />
          {t("Forhåndsvisning ved deling i sosiale medier")}
        </Heading>
        <Text fontSize=".9rem" opacity=".8">
          {t(
            "Facebook og andre sosiale medier styrer selv hvordan layout på forhåndsvisning blir, men sannsynligvis vil det se omtrent slik ut:"
          )}
        </Text>
        <SOMEPreview />
      </Container>
    </Box>
  );
};
