import { isDevelopment } from "@biblioteksentralen/utils";
import { sanityConfig } from "@libry-content/common";
import EventSource from "@sanity/eventsource";
import { createClient, createPreviewSubscriptionHook } from "next-sanity";

export let sanityAuthToken: string | undefined;

// For other browsers than Chrome we need to explicitly set the auth token,
// similarly to https://github.com/sanity-io/sanity/pull/3155
if (typeof window !== "undefined" && !!localStorage) {
  const localStorageToken = localStorage.getItem(`__studio_auth_token_${sanityConfig.projectId}`);
  try {
    sanityAuthToken = localStorageToken ? JSON.parse(localStorageToken)?.token : undefined;
  } catch (err) {
    console.error("Could not parse Sanity auth token from localStorage:", err);
  }
}

export const usePreviewSubscription = createPreviewSubscriptionHook({
  ...sanityConfig,
  token: sanityAuthToken,
  EventSource,
  documentLimit: isDevelopment() ? 7000 : 4000, // Begrensning på 3000 dokumenter skaper trøbbel, spesielt for for admin-brukere som har tilgang til alt, øker grensene som en midlertidig løsning
});

// Don't include token here, or client side fetches will include drafts
export const sanityClient = createClient({
  ...sanityConfig,
  useCdn: process.env.NODE_ENV === "production",
});
